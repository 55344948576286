import * as Sentry from '@sentry/astro'
import type { SupabaseClient } from '@supabase/supabase-js'

import { getSupabaseClient } from '@act/lib/src/supabase'
import type { analyticsEventNames } from '@act/lib/config'
import { supabaseAuthEvents } from '@act/lib/config'

type SupabaseAuthChangeEvent = keyof typeof supabaseAuthEvents
export type CrispEventName = typeof analyticsEventNames[ number ]

export interface CrispEventDetails {
    // The subject of the event
    subject?: string

    userId?: string

    // Viewing Teams and Cohorts
    teamId?: string
    teamName?: string
    cohortId?: string
    cohortName?: string

    // Filtering scores and members on DataViz
    scoreLabels?: string[] | string
    listedMembers?: string[] | string

    // Copying and sharing links
    url?: string

    // Generic message
    message?: string
}

async function setupSentryContext ( client: SupabaseClient = getSupabaseClient() ) {
    // Get the current session
    const { data: context } = await client.auth.getSession()

    if ( context.session ) {
        // Set the user context
        Sentry.setUser( {
            id: context.session.user.id,
        } )
    }
}

// Setup Crisp events for Supabase auth events
// so that we can automatically track them in Crisp
function setupSentrySupabaseAuthEvents ( client: SupabaseClient = getSupabaseClient() ) {
    // Listen for auth events
    client.auth.onAuthStateChange( ( event: SupabaseAuthChangeEvent ) => {
        // console.log( { event, session } )
        const eventName = supabaseAuthEvents[ event ]

        // Push the event to Sentry
        Sentry.metrics.set( 'supa_auth_state', eventName )
    } )
}

export async function sentryBrowserInit () {
    await setupSentryContext()
    await setupSentrySupabaseAuthEvents()
}
